import component from './en-US/component';
import globalHeader from './en-US/globalHeader';
import menu from './en-US/menu';
import pwa from './en-US/pwa';
import string from './en-US/string';
import pages from './en-US/pages';
import error from './en-US/error';

export default {
  ...globalHeader,
  ...menu,
  ...pwa,
  ...component,
  ...string,
  ...pages,
  ...error,
};
