export default {
  'pages.login.title': 'Sign In',
  'pages.login.description': 'Enter your username and password',
  'pages.login.username.label': 'Username',
  'pages.login.username.placeholder': 'Type your username',
  'pages.login.username.required': 'Please input your username',
  'pages.login.password.label': 'Password',
  'pages.login.password.placeholder': 'Type your password',
  'pages.login.password.required': 'Please input your password',
  'pages.login.submit': 'Sign In',
  'pages.login.needHelp': 'Need help? Visit our FAQ',

  'pages.login.error.loginTitle': 'Sign In Failed',
  'pages.login.error.auth.invalid': 'Incorrect Username or Password',
  'pages.login.error.auth.locked': 'Account is locked',
  'pages.login.error.contactAdmin': 'Something went wrong, please contact admin',
};
