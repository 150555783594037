export default {
  'pages.createMessage.title': 'Create Message',
  'pages.createMessage.warning':
    'Reduce your chance from getting ban by sending to recipient that knows you / your brand. You also not allowed to send message containing porn, selling illegal drug/items, or hate speech towards specific race, gender, or religion. Violating the rules will get you a permanent ban from using our tools',

  'pages.createMessage.step1': 'Recipients',
  'pages.createMessage.step1Description': 'Choose who will receive your message',
  'pages.createMessage.step2': 'Compose',
  'pages.createMessage.step2Description': 'Design your message',
  'pages.createMessage.step3': 'Send & Review',
  'pages.createMessage.step3Description': 'Review and schedule when to send',

  'pages.createMessage.recipients.title': 'Choose contact from',

  'pages.createMessage.recipients.radio.upload': 'Upload CSV',
  'pages.createMessage.recipients.radio.segment': 'Contact Segment',
  'pages.createMessage.recipients.radio.contact': 'Contact List or Add Number Manually',

  'pages.createMessage.recipients.uploadBox.title': 'Click or drag file to this area to upload',
  'pages.createMessage.recipients.uploadBox.description':
    'Only support <span style="color:#0A979C">.csv</span> file, make sure you upload the correct file',
  'pages.createMessage.recipients.uploadBox.noticeMessage':
    'Make sure you use file with .csv format and phoneNumber exist in one of the column. Need guide?',
  'pages.createMessage.recipients.uploadBox.download': 'Download Template',
  'pages.createMessage.recipients.uploadBox.uploadFailedTitle': 'Upload Contact Failed',
  'pages.createMessage.recipients.uploadBox.uploadFailedEmpty':
    'Your file is empty. Please recheck your file or download our template and reupload',
  'pages.createMessage.recipients.uploadBox.uploadFailedNoPhone':
    "Your file didn't have any phoneNumber column. Please recheck your file or download our template and reupload",

  'pages.createMessage.recipients.columns.title': 'Check your column title',
  'pages.createMessage.recipients.columns.description':
    'Column title is your first row in CSV and will be use as custom field. Your file have {number} columns',
  'pages.createMessage.recipients.columns.whatIsColumn': 'What is a custom field?',

  'pages.createMessage.recipients.segment.alert':
    'Choose from contact segment. You can select multiple segment at once',
  'pages.createMessage.recipients.segment.searchPlaceholder': 'Search Segment',
  'pages.createMessage.recipients.segment.emptyTable': 'No segment to display',
  'pages.createMessage.recipients.segment.noContactSelected': 'No contact selected yet',
  'pages.createMessage.recipients.segment.totalSelected': 'Total contact selected: {number}',
  'pages.createMessage.recipients.segment.columnName': 'Segment Name',
  'pages.createMessage.recipients.segment.columnTotal': 'Total Contact',

  'pages.createMessage.recipients.contact.allContact': 'All Contact',
  'pages.createMessage.recipients.contact.selectedContact': 'Selected Contact',
  'pages.createMessage.recipients.contact.searchPlaceholder': 'Search contact',
  'pages.createMessage.recipients.contact.add': 'Add Number',
  'pages.createMessage.recipients.contact.add.title': 'Add Contact',
  'pages.createMessage.recipients.contact.add.phoneTitle': 'Phone Number',
  'pages.createMessage.recipients.contact.add.phonePlaceholder': 'Example: 62812xxxx',
  'pages.createMessage.recipients.contact.add.phoneRequired': 'Phone number is required',
  'pages.createMessage.recipients.contact.add.phoneInvalid': 'Phone number is not valid',
  'pages.createMessage.recipients.contact.add.phoneExist':
    'Phone number already exists in contact list',
  'pages.createMessage.recipients.contact.add.firstNameTitle': 'First Name',
  'pages.createMessage.recipients.contact.add.firstNamePlaceholder': 'Type your first name',
  'pages.createMessage.recipients.contact.add.firstNameRequired': 'First name is required',
  'pages.createMessage.recipients.contact.add.lastNameTitle': 'Last Name',
  'pages.createMessage.recipients.contact.add.lastNamePlaceholder': 'Type your last name',
  'pages.createMessage.recipients.contact.add.lastNameRequired': 'Last name is required',
  'pages.createMessage.recipients.contact.add.cancel': 'Cancel',
  'pages.createMessage.recipients.contact.add.submit': 'Add',
  'pages.createMessage.recipients.contact.add.alert':
    'Contact that were added will not be save to all contact',

  'pages.createMessage.recipients.summary.title': 'Upload Summary',
  'pages.createMessage.recipients.summary.total': 'Total contact uploaded: {number}',
  'pages.createMessage.recipients.summary.numberOfContacts': '{number} Contact',
  'pages.createMessage.recipients.summary.valid': 'Valid',
  'pages.createMessage.recipients.summary.validDescription': 'Will be receiving the message',
  'pages.createMessage.recipients.summary.invalid': 'Invalid',
  'pages.createMessage.recipients.summary.invalidDescription':
    'Will not be receiving the message due to incorrect number format',
  'pages.createMessage.recipients.summary.viewInvalid': 'View invalid contact',
  'pages.createMessage.recipients.summary.duplicated': 'Duplicated',
  'pages.createMessage.recipients.summary.duplicatedDescription':
    'Will not be receiving the message due to duplication',
  'pages.createMessage.recipients.summary.viewDuplicated': 'View duplicated contact',
  'pages.createMessage.recipients.summary.generalModalColumn': 'Phone Number',
  'pages.createMessage.recipients.summary.invalidModalTitle': 'Invalid Contact',
  'pages.createMessage.recipients.summary.invalidModalAlert':
    'These contacts will not be receving message due to incorrect number format',
  'pages.createMessage.recipients.summary.duplicatedModalTitle': 'Duplicated Contact',
  'pages.createMessage.recipients.summary.duplicatedModalAlert':
    'These contacts were duplicated and will only receive message once',

  'pages.createMessage.recipients.fileUploaded': 'File uploaded',
  'pages.createMessage.recipients.buttons.changeFile': 'Change',

  'pages.createMessage.message.title': 'Write Content',
  'pages.createMessage.message.description': 'Use template or write from scratch',
  'pages.createMessage.message.manual': 'Write Manually',
  'pages.createMessage.message.manualDescription': 'Write your message from scratch',
  'pages.createMessage.message.template': 'Use Message Template',
  'pages.createMessage.message.templateDescription':
    'Choose from the list of template you’ve created',
  'pages.createMessage.message.useTemplate': 'Use Template',
  'pages.createMessage.message.changeTemplate': 'Change Template',
  'pages.createMessage.message.customField': 'Custom Field:',
  'pages.createMessage.message.messagePlaceholder': 'Write your message here',
  'pages.createMessage.message.preview': 'Preview',
  'pages.createMessage.message.previewDescription': 'Your message can be previewed here',
  'pages.createMessage.message.templateModal.title': 'Select Template',
  'pages.createMessage.message.templateModal.emptyTable': 'No template to display',
  'pages.createMessage.message.templateModal.columnTitle': 'Title',
  'pages.createMessage.message.templateModal.columnMessage': 'Message',
  'pages.createMessage.message.templateModal.columnAttachment': 'Attachment',
  'pages.createMessage.message.templateModal.columnActions': 'Actions',
  'pages.createMessage.message.templateModal.select': 'Select Template',
  'pages.createMessage.message.templateModal.searchPlaceholder': 'Search template',
  'pages.createMessage.message.templateModal.close': 'Close',
  'pages.createMessage.message.templateModal.confirmation':
    'Your current editing will be lost. Are you sure you want to use this template?',
  'pages.createMessage.message.templateModal.confirmationYes': 'Use Template',
  'pages.createMessage.message.templateModal.confirmationNo': 'No',

  'pages.createMessage.send.title': 'Send Message',
  'pages.createMessage.send.description': 'Double check your message and choose when to send it',
  'pages.createMessage.send.alert':
    'We use anti block engine to protect your account from getting banned by Whatsapp',
  'pages.createMessage.send.messageName': 'Message Name',
  'pages.createMessage.send.scheduleTitle': 'When to send?',
  'pages.createMessage.send.scheduleNow': 'Now',
  'pages.createMessage.send.scheduleDate': 'Choose date and time',
  'pages.createMessage.send.scheduleDatePlaceholder': 'Select date & time',
  'pages.createMessage.send.confirmationTitle': 'Send this message now?',
  'pages.createMessage.send.scheduledConfirmationTitle': 'Schedule this message?',
  'pages.createMessage.send.confirmationDescription':
    'To prevent sending failure, make sure your phone is on and your Whatsapp is connected',
  'pages.createMessage.send.scheduledConfirmationDescription':
    'To prevent sending failure, make sure your phone is on and your Whatsapp is connected at the scheduled time',
  'pages.createMessage.send.confirmationYes': 'Send Now',
  'pages.createMessage.send.scheduledConfirmationYes': 'Confirm Schedule',
  'pages.createMessage.send.confirmationNo': 'Cancel',
  'pages.createMessage.send.successTitle': 'Message successfully created',
  'pages.createMessage.send.successDescription':
    'To prevent sending failure, make sure your phone is on and your Whatsapp is connected',
  'pages.createMessage.send.scheduledSuccessTitle': 'Message successfully scheduled',
  'pages.createMessage.send.scheduledSuccessDescription':
    'To prevent sending failure, make sure your phone is on and your Whatsapp is connected at the scheduled time',
  // 'pages.createMessage.send.antiBlock': 'Use anti block engine',
  // 'pages.createMessage.send.antiBlockTooltips':
  //   'Include a delay and sleep timer to prevent being blocked. Configure it by your own in message settings',

  'pages.createMessage.bottomButtons.cancel': 'Cancel',
  'pages.createMessage.bottomButtons.back': 'Back',
  'pages.createMessage.bottomButtons.continue': 'Continue',
  'pages.createMessage.bottomButtons.composeMessage': 'Compose Message',
  'pages.createMessage.bottomButtons.reviewSegment': 'Review Segment',
  'pages.createMessage.bottomButtons.reviewMessage': 'Review and Send',
  'pages.createMessage.bottomButtons.sendMessage': 'Send Message Now',
  'pages.createMessage.bottomButtons.scheduledSendMessage': 'Schedule Message',
};
