export default {
  'menu.home': 'Beranda',
  'menu.message': 'Pesan',
  'menu.messageDetails': 'Detail',
  'menu.createMessage': 'Buat Pesan',
  'menu.contact': 'Kontak',
  'menu.segmentDetails': 'Detail Segmen',
  'menu.template': 'Template',
  'menu.help': 'Bantuan',
  'menu.admin': 'Admin',
  'menu.admin.users': 'Pengguna',
  'menu.admin.organizations': 'Organisasi',
  'menu.admin.performance': 'Performa',
};
