export default {
  'pages.template.title': 'Template',
  'pages.template.description': 'Create and manage your template',
  'pages.template.searchPlaceholder': 'Search template',
  'pages.template.emptyTable': 'No template to display',
  'pages.template.createTemplate': 'Create Template',
  'pages.template.columnName': 'Title',
  'pages.template.columnMessage': 'Message',
  'pages.template.columnAttachment': 'Attachment',
  'pages.template.columnAction': 'Actions',
  'pages.template.action.edit': 'Edit',
  'pages.template.action.delete': 'Delete',

  'pages.template.createModal.title': 'Create Template',
  'pages.template.createModal.editTitle': 'Edit Template',
  'pages.template.createModal.nameTitle': 'Template Name',
  'pages.template.createModal.namePlaceholder': 'Input template name',
  'pages.template.createModal.nameRequired': 'Template name is required',
  'pages.template.createModal.messageTitle': 'Your Message',
  'pages.template.createModal.messagePlaceholder': 'Input your message',
  'pages.template.createModal.messageRequired': 'Message is required',
  'pages.template.createModal.messageDescription':
    "Use custom field by typing '{{yourCustomField}}'",
  'pages.template.createModal.cancel': 'Cancel',
  'pages.template.createModal.submit': 'Create',
  'pages.template.createModal.editSubmit': 'Save',
  'pages.template.createModal.createSuccess': 'Template has been successfully created',
  'pages.template.createModal.updateSuccess': 'Template has been successfully updated',

  'pages.template.deleteTitle': 'Delete this template?',
  'pages.template.deleteDescription': 'This template will be deleted permanently',
  'pages.template.deleteYes': 'Delete',
  'pages.template.deleteNo': 'Cancel',
  'pages.template.deleteSuccess': 'Template has been successfully deleted',
};
