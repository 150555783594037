export default {
  'pages.message.title': 'Message',
  'pages.message.description': 'Create and send message to multiple contact at once',
  'pages.message.emptyTable': 'No message to display',
  'pages.message.settings': 'Settings',
  'pages.message.createMessage': 'Create Message',
  'pages.message.columnTitle': 'Title',
  'pages.message.columnSendTo': 'Send To',
  'pages.message.columnDelivered': 'Delivered',
  'pages.message.columnStatus': 'Status',
  'pages.message.columnCreatedBy': 'Created By',
  'pages.message.columnWhatsappNo': 'Whatsapp No.',
  'pages.message.columnActions': 'Actions',

  'pages.message.action.detail': 'Detail',
  'pages.message.action.cancel': 'Cancel',
  'pages.message.action.resend': 'Resend',
  'pages.message.action.download': 'Download',

  'pages.message.date.all': 'All message',
  'pages.message.date.last7': 'Last 7 days',
  'pages.message.date.last14': 'Last 14 days',
  'pages.message.date.last30': 'Last 30 days',
  'pages.message.date.last3m': 'Last 3 months',
  'pages.message.willSendAt': 'Will send at {date}',
  'pages.message.sentOn': 'Sent on {date}',
  'pages.message.searchPlaceholder': 'Search message',
  'pages.message.deleteButton': 'Delete Message',

  'pages.message.cancelTitle': 'Cancel this message?',
  'pages.message.cancelDescription': 'This scheduled message will be cancelled and not be sent',
  'pages.message.cancelYes': 'Cancel Schedule',
  'pages.message.resendTitle': 'Resend this messages?',
  'pages.message.resendDescription': 'This message will be sent immediately',
  'pages.message.resendYes': 'Resend Message',
  'pages.message.deleteTitle': 'Delete these messages?',
  'pages.message.deleteDescription': 'The selected messages will be deleted',
  'pages.message.deleteYes': 'Delete',
  'pages.message.generalNo': 'Cancel',
  'pages.message.downloadTitle': 'Download Message Report',
  'pages.message.downloadDescription':
    "The delivery status of each contact will be exported in CSV format.\n\nIf the message is sent to many contacts, this data processing may take longer.\n\nThe download link will be sent to notification once it's done.",
  'pages.message.downloadYes': 'Download',

  'pages.message.cancelSuccess': 'Message has been successfully cancelled',
  'pages.message.resendSuccess': 'Message has been successfully initiated to be resent',
  'pages.message.deleteSuccess': 'Messages have been successfully deleted',
  'pages.message.downloadSuccess': 'Message report download request is being processed',

  'pages.messageDetails.title': 'Detail',
  'pages.messageDetails.sendAt': 'Send At',
  'pages.messageDetails.contactCount': '{number} Contact',
  'pages.messageDetails.createdAt': 'Created At',
  'pages.messageDetails.message': 'Message',
  'pages.messageDetails.attachment': 'Attachment',
  'pages.messageDetails.messagePreview': 'Message Preview',
  'pages.messageDetails.cancel': 'Cancel Schedule',
  'pages.messageDetails.resend': 'Resend Message',
  'pages.messageDetails.download': 'Download Report',

  'pages.message.status.initiate': 'Initiate',
  'pages.message.status.sending': 'Sending',
  'pages.message.status.completed': 'Completed',
  'pages.message.status.scheduled': 'Scheduled',
  'pages.message.status.failed': 'Failed',
  'pages.message.status.cancelled': 'Cancelled',
};
