import component from './id-ID/component';
import globalHeader from './id-ID/globalHeader';
import menu from './id-ID/menu';
import pwa from './id-ID/pwa';
import string from './id-ID/string';
import pages from './id-ID/pages';
import error from './id-ID/error';

export default {
  ...globalHeader,
  ...menu,
  ...pwa,
  ...component,
  ...string,
  ...pages,
  ...error,
};
