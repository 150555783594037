export default {
  'pages.login.title': 'Masuk',
  'pages.login.description': 'Masukkan username dan kata sandi kamu',
  'pages.login.username.label': 'Username',
  'pages.login.username.placeholder': 'Ketik username kamu',
  'pages.login.username.required': 'Silakan masukkan username kamu',
  'pages.login.password.label': 'Kata Sandi',
  'pages.login.password.placeholder': 'Ketik kata sandi kamu',
  'pages.login.password.required': 'Silakan masukkan kata sandi kamu',
  'pages.login.submit': 'Masuk',
  'pages.login.needHelp': 'Butuh bantuan? Kunjungi FAQ kami',

  'pages.login.error.loginTitle': 'Gagal Masuk',
  'pages.login.error.auth.invalid': 'Nama Pengguna atau Kata Sandi Salah',
  'pages.login.error.auth.locked': 'Akun terkunci',
  'pages.login.error.contactAdmin': 'Ada yang tidak beres, silakan hubungi admin',
};
