export default {
  'pages.home.title': 'Hello, {name} 👋',
  'pages.home.description':
    'Welcome to SendAll, start sending message by connecting your Whatsapp first',
  'pages.home.connectionCard.title': 'Connect your Whatsapp Account',
  'pages.home.connectionCard.description': 'Follow the step below to connect',
  'pages.home.connectionCard.step1':
    'Open the Whatsapp app in your phone. <span class="font-medium">DON’T USE WHATSAPP BETA</span>',
  'pages.home.connectionCard.step2':
    'Tap Menu (Android) or Settings (iPhone), then tap Linked Devices',
  'pages.home.connectionCard.step3': 'Point your camera towards the QR code on the right',
  'pages.home.connectionCard.step4': 'Please wait a bit while Whatsapp is connecting',
  'pages.home.connectionCard.step5':
    'When succeed, status will change to Connected and device information will be visible. When failed, tap the refresh button to get a new QR and try again',
  'pages.home.connectionCard.deviceInfo': 'Device Info:',
  'pages.home.connectionCard.deviceInfoNotConnected': 'Not Connected',
  'pages.home.connectionCard.connected': 'Connected',
  'pages.home.connectionCard.disconnected': 'Disconnected',
  'pages.home.connectionCard.autoRefreshCountdown': 'Refresh in {seconds}s...',
  'pages.home.connectionCard.manualRefreshCountdown': 'Try again in {seconds}s...',
  'pages.home.connectionCard.willRefreshWhenDisconnected': 'Will refresh when disconnected',
  'pages.home.connectionCard.clickToRefresh': 'Press to refresh',
  'pages.home.connectionCard.whatsappConnected': 'Whatsapp successfully connected',
  'pages.home.connectionCard.whatsappDisconnected': 'Whatsapp has been disconnected',
  'pages.home.connectionCard.whatsappFailed': 'Whatsapp connection failed',
  'pages.home.featuresCard.title': 'All Feature',
  'pages.home.featuresCard.sendTitle': 'Message',
  'pages.home.featuresCard.sendDescription': 'Create and send message to multiple contact at once',
  'pages.home.featuresCard.contactTitle': 'Contact',
  'pages.home.featuresCard.contactDescription': 'Add and manage your list of contact',
  'pages.home.featuresCard.templateTitle': 'Template',
  'pages.home.featuresCard.templateDescription': 'Create template to ease your message creation',
  'pages.home.featuresCard.helpTitle': 'Help',
  'pages.home.featuresCard.helpDescription':
    'Having trouble using the tools? Go get the help you need',
  'pages.home.featuresCard.more': 'More',
  'pages.home.connectWhatsappFirst': 'Please connect Whatsapp first before proceeding',
};
