export default {
  'pages.admin.user.title': 'Manage User',
  'pages.admin.user.addNewUser': 'Add New User',

  'pages.admin.user.searchUser': 'Search User',
  'pages.admin.user.columnId': 'ID',
  'pages.admin.user.columnUsername': 'Username',
  'pages.admin.user.columnOrganization': 'Organization',
  'pages.admin.user.columnRole': 'Role',
  'pages.admin.user.columnActive': 'Active',
  'pages.admin.user.columnLocked': 'Locked',
  'pages.admin.user.columnLastLogin': 'Last Login',
  'pages.admin.user.columnAction': 'Action',
  'pages.admin.user.action.resetPassword': 'Reset Password',

  'pages.admin.user.actionMessage': 'Are you sure you want to {action} this user?',
  'pages.admin.user.actionMessage.activate': 'activate',
  'pages.admin.user.actionMessage.deactivate': 'deactivate',
  'pages.admin.user.actionMessage.lock': 'lock',
  'pages.admin.user.actionMessage.unlock': 'unlock',

  'pages.admin.user.createModal.title': 'Add New User',
  'pages.admin.user.createModal.username': 'Username',
  'pages.admin.user.createModal.usernamePlaceholder': 'Input username',
  'pages.admin.user.createModal.usernameRequired': 'Username is required',
  'pages.admin.user.createModal.password': 'Password',
  'pages.admin.user.createModal.passwordPlaceholder': 'Input password',
  'pages.admin.user.createModal.passwordRequired': 'Password is required',
  'pages.admin.user.createModal.email': 'Email',
  'pages.admin.user.createModal.emailPlaceholder': 'Input email',
  'pages.admin.user.createModal.emailRequired': 'Email is required',
  'pages.admin.user.createModal.emailInvalid': 'Email is invalid',
  'pages.admin.user.createModal.organization': 'Organization',
  'pages.admin.user.createModal.organizationPlaceholder': 'Choose Organization',
  'pages.admin.user.createModal.organizationRequired': 'Organization is required',
  'pages.admin.user.createModal.role': 'Role',
  'pages.admin.user.createModal.rolePlaceholder': 'Choose Role',
  'pages.admin.user.createModal.roleRequired': 'Role is required',
  'pages.admin.user.createModal.cancel': 'Cancel',
  'pages.admin.user.createModal.submit': 'Create New User',

  'pages.admin.user.resetPasswordModal.title': "Change User's Password",
  'pages.admin.user.resetPasswordModal.newPassword': 'New Password',
  'pages.admin.user.resetPasswordModal.newPasswordPlaceholder': 'Input new password',
  'pages.admin.user.resetPasswordModal.newPasswordRequired': 'New password is required',
  'pages.admin.user.resetPasswordModal.cancel': 'Cancel',
  'pages.admin.user.resetPasswordModal.submit': 'Change Password',

  'pages.admin.user.createUserSuccess': 'New user has been created',
  'pages.admin.user.updateSuccess': "User's data update success",
  'pages.admin.user.updateFailed': "User's data update failed",
  'pages.admin.user.usernameTaken': 'Username is already taken',

  'pages.admin.organization.title': 'Manage Organization',
  'pages.admin.organization.addNewOrganization': 'Add New Organization',

  'pages.admin.organization.actionMessage': 'Are you sure you want to {action} this organization?',
  'pages.admin.organization.actionMessage.activate': 'activate',
  'pages.admin.organization.actionMessage.deactivate': 'deactivate',

  'pages.admin.organization.searchOrganization': 'Search Organization',
  'pages.admin.organization.columnId': 'ID',
  'pages.admin.organization.columnName': 'Name',
  'pages.admin.organization.columnActive': 'Active',
  'pages.admin.organization.columnAction': 'Action',

  'pages.admin.organization.createModal.title': 'Add New Organization',
  'pages.admin.organization.createModal.name': 'Name',
  'pages.admin.organization.createModal.namePlaceholder': 'Input Name',
  'pages.admin.organization.createModal.nameRequired': 'Name is required',
  'pages.admin.organization.createModal.cancel': 'Cancel',
  'pages.admin.organization.createModal.submit': 'Create New Organization',

  'pages.admin.organization.createOrganizationSuccess': 'New Organization has been created',
  'pages.admin.organization.updateSuccess': "Organization's data update success",
  'pages.admin.organization.updateFailed': "Organization's data update failed",
  'pages.admin.organization.nameTaken': 'Organization Name is already taken',

  'pages.admin.performance.title': 'Performance',
  'pages.admin.performance.stats':
    "User's statistic (Contact: {contact}, Message: {message}, Template: {template})",

  'pages.admin.performance.columnUsername': 'Username',
  'pages.admin.performance.columnUsernameSearch': 'Search username',
  'pages.admin.performance.columnOrganization': 'Organization',
  'pages.admin.performance.columnOrganizationSearch': 'Search Organization',
  'pages.admin.performance.columnLastLogin': 'Last Login',
  'pages.admin.performance.columnTemplate': 'Template',
  'pages.admin.performance.columnMessageSent': 'Message Sent',
  'pages.admin.performance.columnContactsSaved': 'Saved Contacts',

  'pages.error.notHandled': 'Something went wrong, please contact admin',
};
