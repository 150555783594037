import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import get from 'lodash/get';

import { version } from '../../package.json';

class BugsnagClass {
  constructor() {
    this.bugsnagEnabled =
      process.env.NODE_ENV !== 'test' && RELEASE_STAGE === 'production' && BUGSNAG_APIKEY;
    this.initialize();
  }
  setBugsnagObjectMetadata(report, errObj, keyName) {
    Object.keys(errObj).forEach((key) => {
      report.addMetadata(keyName, key, errObj[key]);
    });
  }
  initialize() {
    if (this.bugsnagEnabled) {
      this.Bugsnag = Bugsnag.start({
        apiKey: BUGSNAG_APIKEY,
        autoBreadcrumbs: true,
        plugins: [new BugsnagPluginReact()],
        releaseStage: RELEASE_STAGE,
        appVersion: version,
        onError: async (event) => {
          const { userId, username, organizationId, organizationName } =
            window.sendallUserData || {};

          if (userId) event.addMetadata('user', 'userId', userId);
          if (username) event.addMetadata('user', 'username', username);
          if (organizationId) event.addMetadata('user', 'organizationId', organizationId);
          if (organizationName) event.addMetadata('user', 'organizationName', organizationName);
        },
      });
    }
  }

  error(err, severity = 'warning') {
    if (this.Bugsnag) {
      if (get(err, 'message') === 'Network Error') {
        // eslint-disable-next-line no-param-reassign
        severity = 'warning';
      }
      Bugsnag.notify(err, (report) => {
        if (err?.config) {
          const { headers, method, params, data, url } = err.config;
          report.context =
            err.message === 'Network Error'
              ? `Network Error (${url})`
              : `Request failed with status code ${err?.response?.status} (${url})`;
          this.setBugsnagObjectMetadata(
            report,
            {
              headers,
              params,
              requestData: data,
              responseData: err?.response?.data,
              status: err?.response?.status,
              method,
              url,
            },
            'metadata',
          );
        } else if (err.graphql) {
          report.context = `GQL${
            err.graphql?.path ? ` | Path: ${err.graphql.path.join(', ')}` : ''
          }${err.graphql?.extensions?.code ? ` | Code: ${err.graphql.extensions.code}` : ''}`;
          this.setBugsnagObjectMetadata(report, err.graphql, 'metadata');
        } else {
          this.setBugsnagObjectMetadata(
            report,
            { metadata: JSON.parse(JSON.stringify(err)) },
            'metadata',
          );
        }
        // ["info", "warning", "error"]
        report.severity = severity;

        if (err.customContext) {
          report.context = err.customContext;
        }
      });
    }
  }
}

// const setBugsnagObjectMetadata = (report, errObj) => {
//   Object.keys(errObj).forEach((key) => {
//     report.addMetadata('metadata', key, errObj[key]);
//   });
// };

export default new BugsnagClass();
