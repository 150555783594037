export default {
  'globalHeader.notificationIcon.request.processing':
    'Permintaan kamu sedang diproses, mohon tunggu sebentar',
  'globalHeader.notificationIcon.request.failed': 'Permintaan gagal',
  'globalHeader.notificationIcon.request.completed': 'Permintaan selesai',

  'globalHeader.notificationIcon.campaign.scheduled':
    'Permintaan pesan kamu sekarang sedang dimasukkan ke dalam antrian jadwal, harap tunggu sebentar',
  'globalHeader.notificationIcon.campaign.initiate':
    'Permintaan pesan kamu sekarang sedang dalam antrian, harap tunggu sebentar',
  'globalHeader.notificationIcon.campaign.sending':
    'Permintaan pesan kamu sedang diproses, mohon tunggu sebentar',
  'globalHeader.notificationIcon.campaign.processing':
    'Permintaan pesan kamu sedang diproses, mohon tunggu sebentar',
  'globalHeader.notificationIcon.campaign.failed': 'Permintaan pesan gagal',
  'globalHeader.notificationIcon.campaign.completed': 'Permintaan pesan selesai',
  'globalHeader.notificationIcon.campaign.skipped':
    'Permintaan pesan dilewati karena sesi Whatsapp tidak aktif',
  'globalHeader.notificationIcon.campaign.cancelled': 'Permintaan pesan dibatalkan',

  'globalHeader.notificationIcon.download.processing':
    'Permintaan unduhan kamu sedang diproses, harap tunggu sebentar',
  'globalHeader.notificationIcon.download.failed': 'Permintaan unduhan gagal',
  'globalHeader.notificationIcon.download.completed': 'Permintaan unduhan selesai',

  'globalHeader.notificationIcon.clickToDownload': 'Klik di sini untuk mengunduh',
  'globalHeader.notificationIcon.downloadWillExpire': 'Akan kedaluwarsa dalam {hour} jam',
  'globalHeader.notificationIcon.campaignDetails': 'Terkirim: {sent} | Tidak terkirim: {unsent}',

  'globalHeader.notificationIcon.requestNotificationMessage': 'Permintaan Selesai',
  'globalHeader.notificationIcon.requestNotificationDescription':
    'Kamu memiliki {count} permintaan selesai',
  'globalHeader.notificationIcon.campaignNotificationMessage': 'Kampanye Selesai',
  'globalHeader.notificationIcon.campaignNotificationDescription':
    'Kamu memiliki {count} pesan selesai',
  'globalHeader.notificationIcon.downloadNotificationMessage': 'Unduhan siap',
  'globalHeader.notificationIcon.downloadNotificationDescription':
    'Kamu memiliki {count} unduhan yang siap',
};
