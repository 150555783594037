import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

import { setAuthority } from '@/utils/authority';
import request from '@/utils/request';

export type LoginParamsType = {
  userName: string;
  password: string;
  mobile: string;
  captcha: string;
};

export interface LoginParams {
  username: string;
  password: string;
}

export async function fakeAccountLogin(params: LoginParamsType) {
  return request('/api/login/account', {
    method: 'POST',
    data: params,
  });
}

export async function getFakeCaptcha(mobile: string) {
  return request(`/api/login/captcha?mobile=${mobile}`);
}

export async function getMe() {
  return request('/auth/me', {
    errorHandler: (e) => {
      // Intercept default error handler to not show error notification
      throw e;
    },
  }).then((res) => {
    return res;
  });
}

export async function login(params: LoginParams) {
  return request('/auth/login', {
    method: 'POST',
    data: params,
    errorHandler: (e) => {
      // Intercept default error handler to not show error notification
      throw e;
    },
  }).then((res) => {
    if (res.token) {
      const decoded = jwt.decode(res.token) as any;

      // convert token expire time to day
      Cookies.set('sendall-token', res.token, { expires: (decoded.exp - decoded.iat) / 86400 });

      setAuthority(decoded?.session?.roles || []);
      return decoded?.session;
    }
  });
}

export async function logout() {
  return request('/auth/logout', {
    method: 'POST',
  }).then((res) => {
    setAuthority(null);
    Cookies.remove('sendall-token');
    return res;
  });
}
