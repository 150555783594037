export default {
  'error.status.400': 'There was an error in the request sent. Please contact the admin',
  'error.status.401': 'The request is unauthorized',
  'error.status.403': 'The request is forbidden',
  'error.status.404': 'Whoops! Resource does not exist',
  'error.status.406': 'Wrong format request. Please contact the admin',
  'error.status.410': 'Resource is permanently deleted and no longer available',
  'error.status.422': 'Bad request!',
  'error.status.500': 'Something went wrong. Please contact the admin',
  'error.status.502': 'Server is unreachable. Please try again later',
  'error.status.503': 'Server is currently unavailable. Please contact the admin',
  'error.status.504': 'Timeout',

  'error.unauthorized.title': 'Invalid or expired session',
  'error.unauthorized.description': 'Please log in again',

  'error.general.title': 'Request failed',

  'error.unknown.title': 'Something went wrong',
  'error.unknown.description': 'Please contact admin',
};
