export default {
  'pages.home.title': 'Halo, {name} 👋',

  'pages.home.description':
    'Selamat datang di SendAll, mulailah mengirim pesan dengan menghubungkan Whatsapp kamu terlebih dahulu',
  'pages.home.connectionCard.title': 'Hubungkan Akun Whatsapp kamu',
  'pages.home.connectionCard.description': 'Ikuti langkah di bawah untuk menghubungkan',
  'pages.home.connectionCard.step1':
    'Buka aplikasi Whatsapp di ponsel kamu. <span class="font-medium">JANGAN GUNAKAN WHATSAPP BETA</span>',
  'pages.home.connectionCard.step2':
    'Pilih Menu (Android) atau Pengaturan (iPhone), lalu pilih Perangkat Tertaut / Linked Device',
  'pages.home.connectionCard.step3': 'Arahkan kamera kamu ke kode QR di sebelah kanan',
  'pages.home.connectionCard.step4': 'Harap tunggu sebentar sementara Whatsapp sedang dihubungkan',
  'pages.home.connectionCard.step5':
    'Jika berhasil, status akan berubah menjadi Terhubung dan informasi perangkat akan terlihat. Jika gagal, pilih tombol segarkan untuk mendapatkan QR baru dan coba lagi',
  'pages.home.connectionCard.deviceInfo': 'Info Perangkat:',
  'pages.home.connectionCard.deviceInfoNotConnected': 'Belum terhubung',
  'pages.home.connectionCard.connected': 'Terhubung',
  'pages.home.connectionCard.disconnected': 'Belum terhubung',
  'pages.home.connectionCard.autoRefreshCountdown': 'Disegarkan dalam {seconds} detik...',
  'pages.home.connectionCard.manualRefreshCountdown': 'Coba lagi dalam {seconds}...',
  'pages.home.connectionCard.willRefreshWhenDisconnected': 'Akan diperbarui ketika terputus',
  'pages.home.connectionCard.clickToRefresh': 'Klik untuk QR baru',
  'pages.home.connectionCard.whatsappConnected': 'Whatsapp berhasil terhubung',
  'pages.home.connectionCard.whatsappDisconnected': 'Whatsapp telah terputus',
  'pages.home.connectionCard.whatsappFailed': 'Koneksi Whatsapp gagal',
  'pages.home.featuresCard.title': 'Semua Fitur',
  'pages.home.featuresCard.sendTitle': 'Pesan',
  'pages.home.featuresCard.sendDescription': 'Buat dan kirim pesan ke beberapa kontak sekaligus',
  'pages.home.featuresCard.contactTitle': 'Kontak',
  'pages.home.featuresCard.contactDescription': 'Tambah dan kelola daftar kontak kamu',
  'pages.home.featuresCard.templateTitle': 'Template',
  'pages.home.featuresCard.templateDescription':
    'Buat template untuk memudahkan pembuatan pesan kamu',
  'pages.home.featuresCard.helpTitle': 'Bantuan',
  'pages.home.featuresCard.helpDescription': 'Mengalami kendala? Dapatkan bantuan',
  'pages.home.featuresCard.more': 'Detail',
  'pages.home.connectWhatsappFirst':
    'Harap sambungkan Whatsapp terlebih dahulu sebelum melanjutkan',
};
