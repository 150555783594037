export default {
  'component.avatarDropdown.menu.logout': 'Logout',

  'component.mediaUpload.addMedia': 'Add File / Image',
  'component.mediaUpload.sizeLimitMB': 'File size is exceeding the max size limit ({mb} MB)',
  'component.mediaUpload.mediaConfig.position': 'Attachment position:',
  'component.mediaUpload.mediaConfig.before': 'Before Message',
  'component.mediaUpload.mediaConfig.after': 'After Message',
  'component.mediaUpload.mediaConfig.caption': 'Within Caption',

  'component.modal.export.name': 'Name your file',
  'component.modal.export.namePrefix': 'File Name',
  'component.modal.export.cancel': 'Cancel',
  'component.modal.export.submit': 'Export',

  'component.pagination.totalString': '{range1}-{range2} of {total} items',

  'component.modal.editAntiBlock.title': 'Message Setting',
  'component.modal.editAntiBlock.alert':
    'You can adjust the anti block setting but we suggest to keep the current setting',
  'component.modal.editAntiBlock.delayDuration.title': 'Delay per Message (secs)',
  'component.modal.editAntiBlock.delayDuration.description': 'Delay time between each message sent',
  'component.modal.editAntiBlock.sleepCount.title': 'No. of Message Sent Before Sleep',
  'component.modal.editAntiBlock.sleepCount.description': 'Total message required to trigger sleep',
  'component.modal.editAntiBlock.sleepDuration.title': 'Sleep Durations (secs)',
  'component.modal.editAntiBlock.sleepDuration.description': 'Durations of the sleep',

  'component.modal.editAntiBlock.cancel': 'Cancel',
  'component.modal.editAntiBlock.reset': 'Revert Setting',
  'component.modal.editAntiBlock.submit': 'Save Changes',

  'component.modal.editAntiBlock.popupReset.normal':
    'This will remove all of your current setting. Are you sure you want to continue?',
  'component.modal.editAntiBlock.popupReset.cancel': 'Cancel',
  'component.modal.editAntiBlock.popupReset.submit': 'Revert',

  'component.modal.editAntiBlock.popupConfirm.normal': 'Are you sure you want to continue?',
  'component.modal.editAntiBlock.popupConfirm.below':
    'Some of your setting is lower than our suggestion. Are you sure you want to continue?',
  'component.modal.editAntiBlock.popupConfirm.cancel': 'Cancel',
  'component.modal.editAntiBlock.popupConfirm.submit': 'Continue Save',
};
