export default {
  'pages.message.title': 'Pesan',
  'pages.message.description': 'Buat dan kirim pesan ke beberapa kontak sekaligus',
  'pages.message.emptyTable': 'Tidak ada pesan untuk ditampilkan',
  'pages.message.settings': 'Pengaturan',
  'pages.message.createMessage': 'Buat Pesan',
  'pages.message.columnTitle': 'Judul',
  'pages.message.columnSendTo': 'Kirim Ke',
  'pages.message.columnDelivered': 'Terkirim',
  'pages.message.columnStatus': 'Status',
  'pages.message.columnCreatedBy': 'Dibuat Oleh',
  'pages.message.columnWhatsappNo': 'No. WhatsApp',
  'pages.message.columnActions': 'Aksi',

  'pages.message.action.detail': 'Detail',
  'pages.message.action.cancel': 'Batal',
  'pages.message.action.resend': 'Kirim Ulang',
  'pages.message.action.download': 'Unduh',

  'pages.message.date.all': 'Semua pesan',
  'pages.message.date.last7': '7 hari terakhir',
  'pages.message.date.last14': '14 hari terakhir',
  'pages.message.date.last30': '30 hari terakhir',
  'pages.message.date.last3m': '3 bulan terakhir',
  'pages.message.willSendAt': 'Akan dikirim pada {date}',
  'pages.message.sentOn': 'Dikirim pada {date}',
  'pages.message.searchPlaceholder': 'Cari pesan',
  'pages.message.deleteButton': 'Hapus Pesan',

  'pages.message.cancelTitle': 'Batalkan pesan ini?',
  'pages.message.cancelDescription': 'Pesan terjadwal ini akan dibatalkan dan tidak akan dikirim',
  'pages.message.cancelYes': 'Batalkan Jadwal',
  'pages.message.resendTitle': 'Kirim ulang pesan ini?',
  'pages.message.resendDescription': 'Pesan ini akan segera dikirim',
  'pages.message.resendYes': 'Kirim Ulang Pesan',
  'pages.message.deleteTitle': 'Hapus pesan ini?',
  'pages.message.deleteDescription': 'Pesan yang dipilih akan dihapus',
  'pages.message.deleteYes': 'Hapus',
  'pages.message.generalNo': 'Batal',
  'pages.message.downloadTitle': 'Unduh Laporan Pesan',
  'pages.message.downloadDescription':
    'Status pengiriman setiap kontak akan diekspor dalam format CSV.\n\nJika pesan dikirim ke banyak kontak, pemrosesan data ini mungkin memakan waktu lebih lama.\n\nTautan unduhan akan dikirim ke pemberitahuan setelah selesai.',
  'pages.message.downloadYes': 'Unduh',

  'pages.message.cancelSuccess': 'Pesan telah berhasil dibatalkan',
  'pages.message.resendSuccess': 'Pesan telah berhasil dimulai untuk dikirim ulang',
  'pages.message.deleteSuccess': 'Pesan telah berhasil dihapus',
  'pages.message.downloadSuccess': 'Permintaan unduhan laporan pesan sedang diproses',

  'pages.messageDetails.title': 'Detail',
  'pages.messageDetails.sendAt': 'Dikirim Pada',
  'pages.messageDetails.contactCount': '{number} Kontak',
  'pages.messageDetails.createdAt': 'Dibuat Pada',
  'pages.messageDetails.message': 'Pesan',
  'pages.messageDetails.attachment': 'Lampiran',
  'pages.messageDetails.messagePreview': 'Pratinjau Pesan',
  'pages.messageDetails.cancel': 'Batalkan Jadwal',
  'pages.messageDetails.resend': 'Kirim Ulang Pesan',
  'pages.messageDetails.download': 'Unduh Laporan',

  'pages.message.status.initiate': 'Memulai',
  'pages.message.status.sending': 'Mengirim',
  'pages.message.status.completed': 'Selesai',
  'pages.message.status.scheduled': 'Dijadwalkan',
  'pages.message.status.failed': 'Gagal',
  'pages.message.status.cancelled': 'Dibatalkan',
};
