export default {
  'pages.createMessage.title': 'Buat Pesan',
  'pages.createMessage.warning':
    'Kurangi peluang kamu terkena blokir dengan mengirimkan pesan hanya ke penerima yang mengenal kamu / brand kamu. Kamu juga tidak diperbolehkan mengirim pesan yang mengandung pornografi, menjual obat/barang terlarang, atau ujaran kebencian terhadap ras, jenis kelamin, atau agama tertentu. Melanggar aturan akan membuat kamu dilarang untuk menggunakan SendAll',

  'pages.createMessage.step1': 'Penerima',
  'pages.createMessage.step1Description': 'Pilih siapa penerima pesanmu',
  'pages.createMessage.step2': 'Tulis Pesan',
  'pages.createMessage.step2Description': 'Desain pesan kamu',
  'pages.createMessage.step3': 'Kirim & Tinjau',
  'pages.createMessage.step3Description': 'Tinjau dan jadwalkan pengiriman',

  'pages.createMessage.recipients.title': 'Pilih kontak dari',

  'pages.createMessage.recipients.radio.upload': 'Unggah CSV',
  'pages.createMessage.recipients.radio.segment': 'Segmen Kontak',
  'pages.createMessage.recipients.radio.contact':
    'Daftar Kontak atau Tambahkan Nomor Secara Manual',

  'pages.createMessage.recipients.uploadBox.title':
    'Klik atau seret file ke area ini untuk mengunggah',
  'pages.createMessage.recipients.uploadBox.description':
    'Hanya mendukung file <span style="color:#0A979C">.csv</span>, pastikan kamu mengunggah file yang benar',
  'pages.createMessage.recipients.uploadBox.noticeMessage':
    'Pastikan kamu menggunakan file dengan format .csv dan phoneNumber ada di salah satu kolom. Butuh panduan?',
  'pages.createMessage.recipients.uploadBox.download': 'Unduh Template',
  'pages.createMessage.recipients.uploadBox.uploadFailedTitle': 'Unggah Kontak Gagal',
  'pages.createMessage.recipients.uploadBox.uploadFailedEmpty':
    'File kamu kosong. Silakan periksa kembali filenya atau unduh template kami dan unggah kembali',
  'pages.createMessage.recipients.uploadBox.uploadFailedNoPhone':
    'File kamu tidak memiliki kolom phoneNumber. Silakan periksa kembali filenya atau unduh template kami dan unggah kembali',

  'pages.createMessage.recipients.columns.title': 'Periksa judul kolom kamu',
  'pages.createMessage.recipients.columns.description':
    'Judul kolom adalah baris pertama kamu di CSV dan akan digunakan sebagai Custom Field. File kamu memiliki {number} kolom',
  'pages.createMessage.recipients.columns.whatIsColumn': 'Apa itu Custom Field?',

  'pages.createMessage.recipients.segment.alert':
    'Pilih dari segmen kontak. Kamu dapat memilih beberapa segmen sekaligus',
  'pages.createMessage.recipients.segment.searchPlaceholder': 'Cari Segmen',
  'pages.createMessage.recipients.segment.emptyTable': 'Tidak ada segmen untuk ditampilkan',
  'pages.createMessage.recipients.segment.noContactSelected': 'Belum ada kontak yang dipilih',
  'pages.createMessage.recipients.segment.totalSelected': 'Total kontak yang dipilih: {number}',
  'pages.createMessage.recipients.segment.columnName': 'Nama Segmen',
  'pages.createMessage.recipients.segment.columnTotal': 'Total Kontak',

  'pages.createMessage.recipients.contact.allContact': 'Semua Kontak',
  'pages.createMessage.recipients.contact.selectedContact': 'Kontak Terpilih',
  'pages.createMessage.recipients.contact.searchPlaceholder': 'Cari kontak',
  'pages.createMessage.recipients.contact.add': 'Tambah Nomor',
  'pages.createMessage.recipients.contact.add.title': 'Tambah Kontak',
  'pages.createMessage.recipients.contact.add.phoneTitle': 'Nomor Telepon',
  'pages.createMessage.recipients.contact.add.phonePlaceholder': 'Contoh: 62812xxxx',
  'pages.createMessage.recipients.contact.add.phoneRequired': 'Nomor telepon wajib diisi',
  'pages.createMessage.recipients.contact.add.phoneInvalid': 'Nomor telepon tidak valid',
  'pages.createMessage.recipients.contact.add.phoneExist':
    'Nomor telepon sudah ada di daftar kontak',
  'pages.createMessage.recipients.contact.add.firstNameTitle': 'Nama Depan',
  'pages.createMessage.recipients.contact.add.firstNamePlaceholder': 'Ketik nama depan disini',
  'pages.createMessage.recipients.contact.add.firstNameRequired': 'Nama depan wajib diisi',
  'pages.createMessage.recipients.contact.add.lastNameTitle': 'Nama Belakang',
  'pages.createMessage.recipients.contact.add.lastNamePlaceholder': 'Ketik nama belakang disini',
  'pages.createMessage.recipients.contact.add.lastNameRequired': 'Nama belakang wajib diisi',
  'pages.createMessage.recipients.contact.add.cancel': 'Batal',
  'pages.createMessage.recipients.contact.add.submit': 'Tambah',
  'pages.createMessage.recipients.contact.add.alert':
    'Kontak yang ditambahkan tidak akan disimpan ke semua kontak',

  'pages.createMessage.recipients.summary.title': 'Ringkasan Unggahan',
  'pages.createMessage.recipients.summary.total': 'Total kontak yang diunggah: {number}',
  'pages.createMessage.recipients.summary.numberOfContacts': '{number} Kontak',
  'pages.createMessage.recipients.summary.valid': 'Valid',
  'pages.createMessage.recipients.summary.validDescription': 'Akan menerima pesan',
  'pages.createMessage.recipients.summary.invalid': 'Tidak valid',
  'pages.createMessage.recipients.summary.invalidDescription':
    'Tidak akan menerima pesan karena format nomor yang salah',
  'pages.createMessage.recipients.summary.viewInvalid': 'Lihat kontak tidak valid',
  'pages.createMessage.recipients.summary.duplicated': 'Duplikat',
  'pages.createMessage.recipients.summary.duplicatedDescription':
    'Tidak akan menerima pesan karena duplikat',
  'pages.createMessage.recipients.summary.viewDuplicated': 'Lihat kontak duplikat',
  'pages.createMessage.recipients.summary.generalModalColumn': 'Nomor Telepon',
  'pages.createMessage.recipients.summary.invalidModalTitle': 'Kontak Tidak Valid',
  'pages.createMessage.recipients.summary.invalidModalAlert':
    'Kontak ini tidak akan menerima pesan karena format nomor yang salah',
  'pages.createMessage.recipients.summary.duplicatedModalTitle': 'Kontak Duplikat',
  'pages.createMessage.recipients.summary.duplicatedModalAlert':
    'Kontak ini terduplikasi dan hanya akan menerima pesan satu kali',

  'pages.createMessage.recipients.fileUploaded': 'File unggahan',
  'pages.createMessage.recipients.buttons.changeFile': 'Ubah',

  'pages.createMessage.message.title': 'Tulis Konten',
  'pages.createMessage.message.description': 'Gunakan template atau tulis dari awal',
  'pages.createMessage.message.manual': 'Tulis Secara Manual',
  'pages.createMessage.message.manualDescription': 'Tulis pesan kamu dari awal',
  'pages.createMessage.message.template': 'Gunakan Template Pesan',
  'pages.createMessage.message.templateDescription': 'Pilih dari daftar template yang kamu buat',
  'pages.createMessage.message.useTemplate': 'Gunakan Template',
  'pages.createMessage.message.changeTemplate': 'Ubah Template',
  'pages.createMessage.message.customField': 'Custom Field:',
  'pages.createMessage.message.messagePlaceholder': 'Tulis pesan kamu di sini',
  'pages.createMessage.message.preview': 'Pratinjau',
  'pages.createMessage.message.previewDescription': 'Pesan kamu dapat dipratinjau di sini',
  'pages.createMessage.message.templateModal.title': 'Pilih Template',
  'pages.createMessage.message.templateModal.emptyTable': 'Tidak ada template untuk ditampilkan',
  'pages.createMessage.message.templateModal.columnTitle': 'Judul',
  'pages.createMessage.message.templateModal.columnMessage': 'Pesan',
  'pages.createMessage.message.templateModal.columnAttachment': 'Lampiran',
  'pages.createMessage.message.templateModal.columnActions': 'Aksi',
  'pages.createMessage.message.templateModal.select': 'Pilih Template',
  'pages.createMessage.message.templateModal.searchPlaceholder': 'Cari template',
  'pages.createMessage.message.templateModal.close': 'Tutup',
  'pages.createMessage.message.templateModal.confirmation':
    'Pengeditan kamu saat ini akan hilang. Apakah kamu yakin ingin menggunakan template ini?',
  'pages.createMessage.message.templateModal.confirmationYes': 'Gunakan Template',
  'pages.createMessage.message.templateModal.confirmationNo': 'Tidak',

  'pages.createMessage.send.title': 'Kirim Pesan',
  'pages.createMessage.send.description':
    'Periksa ulang pesan kamu dan pilih kapan harus mengirimnya',
  'pages.createMessage.send.alert':
    'Kami menggunakan sistem anti blokir untuk melindungi akun kamu agar tidak diblokir oleh Whatsapp',
  'pages.createMessage.send.messageName': 'Nama Pesan',
  'pages.createMessage.send.scheduleTitle': 'Kapan harus mengirim?',
  'pages.createMessage.send.scheduleNow': 'Sekarang',
  'pages.createMessage.send.scheduleDate': 'Pilih tanggal dan waktu',
  'pages.createMessage.send.scheduleDatePlaceholder': 'Pilih tanggal & waktu',
  'pages.createMessage.send.confirmationTitle': 'Kirim pesan ini sekarang?',
  'pages.createMessage.send.scheduledConfirmationTitle': 'Jadwalkan pesan ini?',
  'pages.createMessage.send.confirmationDescription':
    'Untuk mencegah kegagalan pengiriman, pastikan ponsel kamu aktif dan Whatsapp kamu terhubung',
  'pages.createMessage.send.scheduledConfirmationDescription':
    'Untuk mencegah kegagalan pengiriman, pastikan ponsel kamu aktif dan Whatsapp kamu terhubung pada waktu yang dijadwalkan',
  'pages.createMessage.send.confirmationYes': 'Kirim Sekarang',
  'pages.createMessage.send.scheduledConfirmationYes': 'Konfirmasi Jadwal',
  'pages.createMessage.send.confirmationNo': 'Batal',
  'pages.createMessage.send.successTitle': 'Pesan berhasil dibuat',
  'pages.createMessage.send.successDescription':
    'Untuk mencegah kegagalan pengiriman, pastikan ponsel kamu aktif dan Whatsapp kamu terhubung',
  'pages.createMessage.send.scheduledSuccessTitle': 'Pesan berhasil dijadwalkan',
  'pages.createMessage.send.scheduledSuccessDescription':
    'Untuk mencegah kegagalan pengiriman, pastikan ponsel kamu aktif dan Whatsapp kamu terhubung pada waktu yang dijadwalkan',
  // 'pages.createMessage.send.antiBlock': 'Gunakan sistem anti blok',
  // 'pages.createMessage.send.antiBlockTooltips':
  // 'Sertakan penundaan dan pengatur waktu tidur untuk mencegah pemblokiran. Konfigurasikan sendiri di pengaturan pesan',

  'pages.createMessage.bottomButtons.cancel': 'Batal',
  'pages.createMessage.bottomButtons.back': 'Kembali',
  'pages.createMessage.bottomButtons.continue': 'Lanjutkan',
  'pages.createMessage.bottomButtons.composeMessage': 'Tulis Pesan',
  'pages.createMessage.bottomButtons.reviewSegment': 'Tinjau Segmen',
  'pages.createMessage.bottomButtons.reviewMessage': 'Tinjau dan Kirim',
  'pages.createMessage.bottomButtons.sendMessage': 'Kirim Pesan Sekarang',
  'pages.createMessage.bottomButtons.scheduledSendMessage': 'Jadwalkan Pesan',
};
