export default {
  'pages.contact.title': 'Manajemen Kontak',
  'pages.contact.total': 'Total Kontak: {number}',
  'pages.contact.description': 'Kelola kontak dan segmen kamu',

  'pages.contact.addToSegmentTitle': 'Tambahkan ke Segmen?',
  'pages.contact.addToSegmentRequired': 'Harap pilih apakah akan menambahkan ke segmen',
  'pages.contact.addToSegment.yes': 'Ya',
  'pages.contact.addToSegment.no': 'Tidak',
  'pages.contact.addToSegment.tooltips': 'Kamu dapat menambahkan kontak ke segmen',
  'pages.contact.chooseSegmentTitle': 'Pilih Segmen',
  'pages.contact.chooseSegmentPlaceholder': 'Cari atau pilih segmen',
  'pages.contact.chooseSegmentRequired': 'Harap pilih setidaknya satu segmen',

  // All contact
  'pages.contact.all.title': 'Semua Kontak',
  'pages.contact.all.searchPlaceholder': 'Cari kontak',
  'pages.contact.all.emptyTable': 'Tidak ada kontak untuk ditampilkan',
  'pages.contact.all.addContact': 'Tambah Kontak',
  'pages.contact.all.addContact.grabWA': 'Ambil Kontak Whatsapp',
  'pages.contact.all.addContact.upload': 'Unggah CSV',
  'pages.contact.all.addContact.manual': 'Tambah Secara Manual',
  'pages.contact.all.columnNumber': 'No. Whatsapp',
  'pages.contact.all.columnFirstName': 'Nama Depan',
  'pages.contact.all.columnLastName': 'Nama Belakang',
  'pages.contact.all.columnFullName': 'Nama Lengkap',
  'pages.contact.all.columnSegment': 'Segmen',
  'pages.contact.all.columnCreatedAt': 'Ditambahkan Pada',
  'pages.contact.all.columnUpdatedAt': 'Diperbarui Pada',
  'pages.contact.all.by': 'oleh {name}',
  'pages.contact.all.columnAction': 'Aksi',
  'pages.contact.all.action.edit': 'Ubah',
  'pages.contact.all.deleteTitle': 'Hapus kontak ini?',
  'pages.contact.all.deleteDescription': 'Kontak yang dipilih akan dihapus secara permanen',
  'pages.contact.all.deleteYes': 'Hapus',
  'pages.contact.all.deleteNo': 'Batal',
  'pages.contact.all.deleteSuccess': 'Kontak telah berhasil dihapus',

  'pages.contact.all.createModal.title': 'Tambah Kontak',
  'pages.contact.all.createModal.editTitle': 'Ubah Kontak',
  'pages.contact.all.createModal.phoneTitle': 'Nomor Telepon',
  'pages.contact.all.createModal.phonePlaceholder': 'Contoh: 62812xxxx',
  'pages.contact.all.createModal.phoneRequired': 'Nomor telepon wajib diisi',
  'pages.contact.all.createModal.phoneInvalid': 'Nomor telepon tidak valid',
  'pages.contact.all.createModal.firstNameTitle': 'Nama Depan',
  'pages.contact.all.createModal.firstNamePlaceholder': 'Ketik nama depan disini',
  'pages.contact.all.createModal.firstNameRequired': 'Nama depan wajib diisi',
  'pages.contact.all.createModal.lastNameTitle': 'Nama Belakang',
  'pages.contact.all.createModal.lastNamePlaceholder': 'Ketik nama belakang disini',
  'pages.contact.all.createModal.lastNameRequired': 'Nama belakang wajib diisi',
  'pages.contact.all.createModal.cancel': 'Batal',
  'pages.contact.all.createModal.submit': 'Kirim',
  'pages.contact.all.createModal.createSuccess': 'Kontak telah berhasil dibuat',
  'pages.contact.all.createModal.updateSuccess': 'Kontak telah berhasil diperbarui',

  'pages.contact.all.grabWA.title': 'Tambah Kontak',
  'pages.contact.all.grabWA.alert':
    'Buat permintaan untuk mengambil kontak dari akun Whatsapp kamu secara otomatis. Ini akan memakan waktu untuk menyelesaikan setelah permintaan dibuat',
  'pages.contact.all.grabWA.sourceTitle': 'Ambil dari',
  'pages.contact.all.grabWA.sourceRequired': 'Silakan pilih tempat pengambilan',
  'pages.contact.all.grabWA.source.all': 'Semua',
  'pages.contact.all.grabWA.source.contact': 'Kontak Saya',
  'pages.contact.all.grabWA.source.chats': 'Obrolan',
  'pages.contact.all.grabWA.source.group': 'Grup',
  'pages.contact.all.grabWA.source.groupTitle': 'Pilih Grup',
  'pages.contact.all.grabWA.source.groupRequired': 'Silakan pilih grup',
  'pages.contact.all.grabWA.source.groupPlaceholder': 'Pilih grup yang akan diambil',
  'pages.contact.all.grabWA.numberFromSource': ' · {number} kontak ditemukan',
  'pages.contact.all.grabWA.cancel': 'Batal',
  'pages.contact.all.grabWA.submit': 'Tambah Kontak',
  'pages.contact.all.grabWA.export': 'Ekspor sebagai CSV',
  'pages.contact.all.grabWA.notificationTitle':
    'Permintaan pengambilan kontak Whatsapp telah diajukan',
  'pages.contact.all.grabWA.notificationDescription':
    'Tunggu sebentar. Kami akan memberi tahu kamu setelah kami selesai mengambil kontak WhatsApp kamu',

  // Segments
  'pages.contact.segment.title': 'Segmen',
  'pages.contact.segment.searchPlaceholder': 'Cari segmen',
  'pages.contact.segment.emptyTable': 'Tidak ada segmen untuk ditampilkan',
  'pages.contact.segment.addSegment': 'Buat Segmen',
  'pages.contact.segment.columnName': 'Nama',
  'pages.contact.segment.columnTotal': 'Total Kontak',
  'pages.contact.segment.columnCreatedAt': 'Ditambahkan Pada',
  'pages.contact.segment.columnUpdatedAt': 'Diperbarui Pada',
  'pages.contact.segment.by': 'oleh {name}',
  'pages.contact.segment.columnAction': 'Aksi',
  'pages.contact.segment.action.detail': 'Detail',
  'pages.contact.segment.action.more': 'Aksi Lain',
  'pages.contact.segment.action.more.addContact': 'Tambah Kontak dari Semua Kontak',
  'pages.contact.segment.action.more.grabWA': 'Ambil Kontak dari Whatsapp',
  'pages.contact.segment.action.more.exportSegment': 'Ekspor Segmen',
  'pages.contact.segment.action.more.editSegment': 'Ubah Segmen',
  'pages.contact.segment.action.more.cloneSegment': 'Duplikat Segmen',
  'pages.contact.segment.action.more.deleteSegment': 'Hapus Secara Permanen',
  'pages.contact.segment.deleteTitle': 'Hapus segmen ini?',
  'pages.contact.segment.deleteDescription': 'Segmen ini akan dihapus secara permanen',
  'pages.contact.segment.deleteYes': 'Hapus',
  'pages.contact.segment.deleteNo': 'Batal',
  'pages.contact.segment.deleteSuccess': 'Segmen telah berhasil dihapus',

  'pages.contact.segment.createModal.title': 'Buat Segmen',
  'pages.contact.segment.createModal.editTitle': 'Ubah Segmen',
  'pages.contact.segment.createModal.cloneTitle': 'Duplikat Segmen',
  'pages.contact.segment.createModal.name': 'Nama Segmen',
  'pages.contact.segment.createModal.namePlaceholder': 'Ketik nama segmen disini',
  'pages.contact.segment.createModal.nameRequired': 'Nama segmen wajib diisi',
  'pages.contact.segment.createModal.color': 'Warna Segmen',
  'pages.contact.segment.createModal.cancel': 'Batal',
  'pages.contact.segment.createModal.create': 'Buat',
  'pages.contact.segment.createModal.update': 'Perbarui',
  'pages.contact.segment.createModal.clone': 'Klon',
  'pages.contact.segment.createModal.createSuccessTitle': 'Segmen Dibuat',
  'pages.contact.segment.createModal.createSuccessDescription':
    'Segmen baru {name} telah berhasil dibuat',
  'pages.contact.segment.createModal.cloneSuccessTitle': 'Segmen Kloning',
  'pages.contact.segment.createModal.cloneSuccessDescription':
    'Segmen baru {name} telah berhasil dikloning',
  'pages.contact.segment.createModal.updateSuccess': 'Segmen telah berhasil diperbarui',

  'pages.contact.segment.export.title': 'Ekspor Segmen',

  // Segment Details
  'pages.contact.segmentContacts.deleteContactButton': 'Hapus Kontak',
  'pages.contact.segmentContacts.total': 'Total kontak: {number}',
  'pages.contact.segmentContacts.emptyTable': 'Tidak ada kontak untuk ditampilkan',
  'pages.contact.segmentContacts.searchPlaceholder': 'Cari kontak',
  'pages.contact.segmentContacts.deleteSegmentButton': 'Hapus Segmen',
  'pages.contact.segmentContacts.deleteTitle': 'Hapus kontak ini?',
  'pages.contact.segmentContacts.deleteDescription':
    'Kontak yang dipilih akan dihapus dari segmen ini',
  'pages.contact.segmentContacts.deleteYes': 'Hapus',
  'pages.contact.segmentContacts.deleteNo': 'Batal',
  'pages.contact.segmentContacts.deleteSuccess': 'Kontak telah berhasil dihapus',
};
