export default {
  'pages.landingPage.joinBeta': 'Gabung jadi Beta Tester',

  'pages.landingPage.introduction.title':
    'Kirim pesan Whatsapp ke ratusan nomor semudah kamu memakai sendal',
  'pages.landingPage.introduction.description':
    'Permudah brand kamu mengirimkan promo atau hal terbaru lainnya ke ratusan pelanggan via Whatsapp dengan mudah tanpa download apapun',

  'pages.landingPage.benefits1.title': 'Tanpa download',
  'pages.landingPage.benefits1.description':
    'Tidak perlu repot lagi download aplikasi. Kamu hanya butuh browser untuk mengakses SendAll',
  'pages.landingPage.benefits2.title': 'Tanpa Whatsapp Business',
  'pages.landingPage.benefits2.description':
    'Cukup dengan akun Whatsapp biasa, kamu sudah bisa menggunakan SendAll',
  'pages.landingPage.benefits3.title': 'Pesan lebih personal',
  'pages.landingPage.benefits3.description':
    'Kamu dapat menambahkan custom field, gambar, maupun video agar pesanmu lebih personal',
  'pages.landingPage.benefits4.title': 'Atur kontak dengan mudah',
  'pages.landingPage.benefits4.description':
    'Bisa ambil kontak dari akun Whatsapp-mu langsung atau upload kontak via CSV',

  'pages.landingPage.usageSafety.title':
    'Kirim pesan ke seluruh kontak Whatsapp-mu tanpa upload ini itu 👌',
  'pages.landingPage.usageSafety.description1':
    'SendAll memiliki fitur ‘Grab from Whatsapp’ yang memudahkanmu untuk menambahkan kontak dari akun Whatsapp yang terhubung ke akun SendAll milikmu tanpa upload CSV.',
  'pages.landingPage.usageSafety.description2':
    'Kamu bisa memilih untuk menambahkan semua kontak yang tersimpan, semua kontak yang lagi ‘chat’ denganmu, maupun semua kontak dari salah satu grup Whatsapp favoritmu',

  'pages.landingPage.help.title': 'Pertanyaan seputar SendAll',

  'pages.landingPage.footer.title':
    'Bantu kami mengembangkan SendAll. Yuk, gabung jadi Beta Tester 🔥',
  'pages.landingPage.footer.trademark': '© SendAll.id 2022',
};
