import { parse } from 'querystring';
import pathRegexp from 'path-to-regexp';

import { getLocale, createIntlCache, createIntl } from 'umi';
import { message } from 'antd';
import type { Route } from '@ant-design/pro-layout/lib/typings';

import { getWAInfo } from '@/services/user';

import en from '@/locales/en-US';
import id from '@/locales/id-ID';

export const getIntl = () => {
  const messageList = {
    'en-US': en,
    'id-ID': id,
  };
  const locale = getLocale();
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messageList[locale],
    },
    cache,
  );
};

const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

export const isUrl = (path: string): boolean => reg.test(path);

export const isAntDesignPro = (): boolean => {
  if (ANT_DESIGN_PRO_ONLY_DO_NOT_USE_IN_YOUR_PRODUCTION === 'site') {
    return true;
  }
  return window.location.hostname === 'preview.pro.ant.design';
};

// 给官方演示站点用，用于关闭真实开发环境不需要使用的特性
export const isAntDesignProOrDev = (): boolean => {
  const { NODE_ENV } = process.env;
  if (NODE_ENV === 'development') {
    return true;
  }
  return isAntDesignPro();
};

export const getPageQuery = () => parse(window.location.href.split('?')[1]);

export const getAuthorityFromRouter = (
  router: Route[] = [],
  pathname: string,
): Route | undefined => {
  const authority = router.find(
    ({ routes, path = '/', target = '_self' }) =>
      (path && target !== '_blank' && pathRegexp(path).exec(pathname)) ||
      (routes && getAuthorityFromRouter(routes, pathname)),
  );
  return authority;
};

export const isImage = (name: string = '') => {
  return !!name.match(/\.(jpeg|jpg|png)$/);
};

export const checkWAActive = async () => {
  try {
    const result = await getWAInfo();
    if (result?.status === 'Connected') {
      return true;
    }
    throw new Error();
  } catch {
    message.warn(
      getIntl().formatMessage({
        id: 'pages.home.connectWhatsappFirst',
      }),
    );
    return false;
  }
};
