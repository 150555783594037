export default {
  'globalHeader.notificationIcon.request.processing':
    'Your request is now being processed, please wait for a while',
  'globalHeader.notificationIcon.request.failed': 'Request failed',
  'globalHeader.notificationIcon.request.completed': 'Request completed',

  'globalHeader.notificationIcon.campaign.scheduled':
    'Your campaign request is now being put in schedule queue please wait for a while',
  'globalHeader.notificationIcon.campaign.initiate':
    'Your campaign request is now being queued, please wait for a while',
  'globalHeader.notificationIcon.campaign.sending':
    'Your campaign request is now being processed, please wait for a while',
  'globalHeader.notificationIcon.campaign.processing':
    'Your campaign request is now being processed, please wait for a while',
  'globalHeader.notificationIcon.campaign.failed': 'Campaign request failed',
  'globalHeader.notificationIcon.campaign.completed': 'Campaign request completed',
  'globalHeader.notificationIcon.campaign.skipped':
    'Campaign request skipped due to inactive whatsapp session',
  'globalHeader.notificationIcon.campaign.cancelled': 'Campaign request cancelled',

  'globalHeader.notificationIcon.download.processing':
    'Your download request is now being processed, please wait for a while',
  'globalHeader.notificationIcon.download.failed': 'Download request failed',
  'globalHeader.notificationIcon.download.completed': 'Download request completed',

  'globalHeader.notificationIcon.clickToDownload': 'Click here to download',
  'globalHeader.notificationIcon.downloadWillExpire': 'Will expired in {hour} hours',
  'globalHeader.notificationIcon.campaignDetails': 'Sent: {sent} | Not sent: {unsent}',

  'globalHeader.notificationIcon.requestNotificationMessage': 'Request Completed',
  'globalHeader.notificationIcon.requestNotificationDescription':
    'You have {count} request completed',
  'globalHeader.notificationIcon.campaignNotificationMessage': 'Campaign Completed',
  'globalHeader.notificationIcon.campaignNotificationDescription':
    'You have {count} campaign completed',
  'globalHeader.notificationIcon.downloadNotificationMessage': 'Download ready',
  'globalHeader.notificationIcon.downloadNotificationDescription':
    'You have {count} download ready',
};
