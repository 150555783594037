export default {
  'menu.home': 'Home',
  'menu.message': 'Message',
  'menu.messageDetails': 'Detail',
  'menu.createMessage': 'Create Message',
  'menu.contact': 'Contact',
  'menu.segmentDetails': 'Segment Detail',
  'menu.template': 'Template',
  'menu.help': 'Help',
  'menu.admin': 'Admin',
  'menu.admin.users': 'Users',
  'menu.admin.organizations': 'Organizations',
  'menu.admin.performance': 'Performance',
};
