// @ts-nocheck

import HomeOutlined from '@ant-design/icons/HomeOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import ContactsOutlined from '@ant-design/icons/ContactsOutlined';
import AppstoreAddOutlined from '@ant-design/icons/AppstoreAddOutlined';
import QuestionCircleOutlined from '@ant-design/icons/QuestionCircleOutlined';
import CrownOutlined from '@ant-design/icons/CrownOutlined'

export default {
  HomeOutlined,
MessageOutlined,
ContactsOutlined,
AppstoreAddOutlined,
QuestionCircleOutlined,
CrownOutlined
}
    