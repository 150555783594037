export default {
  'pages.contact.title': 'Contact Management',
  'pages.contact.total': 'Total Contact: {number}',
  'pages.contact.description': 'Manage your contact and segment',

  'pages.contact.addToSegmentTitle': 'Add to Segment?',
  'pages.contact.addToSegmentRequired': 'Please choose whether to add to segment',
  'pages.contact.addToSegment.yes': 'Yes',
  'pages.contact.addToSegment.no': 'No',
  'pages.contact.addToSegment.tooltips': 'You can add your contact to a segment',
  'pages.contact.chooseSegmentTitle': 'Choose Segment',
  'pages.contact.chooseSegmentPlaceholder': 'Search or choose segment',
  'pages.contact.chooseSegmentRequired': 'Please select at least one segment',

  // All contact
  'pages.contact.all.title': 'All Contacts',
  'pages.contact.all.searchPlaceholder': 'Search contact',
  'pages.contact.all.emptyTable': 'No contact to display',
  'pages.contact.all.addContact': 'Add Contact',
  'pages.contact.all.addContact.grabWA': 'Grab Whatsapp Contact',
  'pages.contact.all.addContact.upload': 'Upload CSV',
  'pages.contact.all.addContact.manual': 'Add Manually',
  'pages.contact.all.columnNumber': 'Whatsapp No.',
  'pages.contact.all.columnFirstName': 'First Name',
  'pages.contact.all.columnLastName': 'Last Name',
  'pages.contact.all.columnFullName': 'Full Name',
  'pages.contact.all.columnSegment': 'Segment',
  'pages.contact.all.columnCreatedAt': 'Added At',
  'pages.contact.all.columnUpdatedAt': 'Updated At',
  'pages.contact.all.by': 'by {name}',
  'pages.contact.all.columnAction': 'Actions',
  'pages.contact.all.action.edit': 'Edit',
  'pages.contact.all.deleteTitle': 'Delete these contacts?',
  'pages.contact.all.deleteDescription': 'The selected contacts will be deleted permanently',
  'pages.contact.all.deleteYes': 'Delete',
  'pages.contact.all.deleteNo': 'Cancel',
  'pages.contact.all.deleteSuccess': 'Contacts have been successfully deleted',

  'pages.contact.all.createModal.title': 'Add Contact',
  'pages.contact.all.createModal.editTitle': 'Edit Contact',
  'pages.contact.all.createModal.phoneTitle': 'Phone Number',
  'pages.contact.all.createModal.phonePlaceholder': 'Example: 62812xxxx',
  'pages.contact.all.createModal.phoneRequired': 'Phone number is required',
  'pages.contact.all.createModal.phoneInvalid': 'Phone number is not valid',
  'pages.contact.all.createModal.firstNameTitle': 'First Name',
  'pages.contact.all.createModal.firstNamePlaceholder': 'Type your first name',
  'pages.contact.all.createModal.firstNameRequired': 'First name is required',
  'pages.contact.all.createModal.lastNameTitle': 'Last Name',
  'pages.contact.all.createModal.lastNamePlaceholder': 'Type your last name',
  'pages.contact.all.createModal.lastNameRequired': 'Last name is required',
  'pages.contact.all.createModal.cancel': 'Cancel',
  'pages.contact.all.createModal.submit': 'Submit',
  'pages.contact.all.createModal.createSuccess': 'Contact has been successfully created',
  'pages.contact.all.createModal.updateSuccess': 'Contact has been successfully updated',

  'pages.contact.all.grabWA.title': 'Add Contacts',
  'pages.contact.all.grabWA.alert':
    'Create a request to automatically grab contact from your Whatsapp account. It will take sometime to complete once requested',
  'pages.contact.all.grabWA.sourceTitle': 'Grab it from',
  'pages.contact.all.grabWA.sourceRequired': 'Please choose where to grab',
  'pages.contact.all.grabWA.source.all': 'All',
  'pages.contact.all.grabWA.source.contact': 'My Contact',
  'pages.contact.all.grabWA.source.chats': 'Chats',
  'pages.contact.all.grabWA.source.group': 'Group',
  'pages.contact.all.grabWA.source.groupTitle': 'Select Group',
  'pages.contact.all.grabWA.source.groupRequired': 'Please select a group',
  'pages.contact.all.grabWA.source.groupPlaceholder': 'Select a group to be grabbed',
  'pages.contact.all.grabWA.numberFromSource': ' · {number} contacts found',
  'pages.contact.all.grabWA.cancel': 'Cancel',
  'pages.contact.all.grabWA.submit': 'Add Contact',
  'pages.contact.all.grabWA.export': 'Export as CSV',
  'pages.contact.all.grabWA.notificationTitle': 'Grab Whatsapp Request Submitted',
  'pages.contact.all.grabWA.notificationDescription':
    'Please wait. We will inform you once we finished grabbing your WhatsApp contact',

  // Segments
  'pages.contact.segment.title': 'Segment',
  'pages.contact.segment.searchPlaceholder': 'Search segment',
  'pages.contact.segment.emptyTable': 'No segment to display',
  'pages.contact.segment.addSegment': 'Create Segment',
  'pages.contact.segment.columnName': 'Name',
  'pages.contact.segment.columnTotal': 'Total Contact',
  'pages.contact.segment.columnCreatedAt': 'Added At',
  'pages.contact.segment.columnUpdatedAt': 'Updated At',
  'pages.contact.segment.by': 'by {name}',
  'pages.contact.segment.columnAction': 'Actions',
  'pages.contact.segment.action.detail': 'Detail',
  'pages.contact.segment.action.more': 'More Actions',
  'pages.contact.segment.action.more.addContact': 'Add Contact from All Contact',
  'pages.contact.segment.action.more.grabWA': 'Grab Contact from Whatsapp',
  'pages.contact.segment.action.more.exportSegment': 'Export Segment',
  'pages.contact.segment.action.more.editSegment': 'Edit Segment',
  'pages.contact.segment.action.more.cloneSegment': 'Duplicate Segment',
  'pages.contact.segment.action.more.deleteSegment': 'Delete Permanently',
  'pages.contact.segment.deleteTitle': 'Delete this segment?',
  'pages.contact.segment.deleteDescription': 'This segment will be deleted permanently',
  'pages.contact.segment.deleteYes': 'Delete',
  'pages.contact.segment.deleteNo': 'Cancel',
  'pages.contact.segment.deleteSuccess': 'Segment has been successfully deleted',

  'pages.contact.segment.createModal.title': 'Create Segment',
  'pages.contact.segment.createModal.editTitle': 'Edit Segment',
  'pages.contact.segment.createModal.cloneTitle': 'Duplicate Segment',
  'pages.contact.segment.createModal.name': 'Segment Name',
  'pages.contact.segment.createModal.namePlaceholder': 'Type your segment name',
  'pages.contact.segment.createModal.nameRequired': 'Segment name is required',
  'pages.contact.segment.createModal.color': 'Segment Color',
  'pages.contact.segment.createModal.cancel': 'Cancel',
  'pages.contact.segment.createModal.create': 'Create',
  'pages.contact.segment.createModal.update': 'Update',
  'pages.contact.segment.createModal.clone': 'Clone',
  'pages.contact.segment.createModal.createSuccessTitle': 'Segment Created',
  'pages.contact.segment.createModal.createSuccessDescription':
    'New {name} has been succesfully created',
  'pages.contact.segment.createModal.cloneSuccessTitle': 'Segment Cloned',
  'pages.contact.segment.createModal.cloneSuccessDescription':
    'New {name} has been succesfully cloned',
  'pages.contact.segment.createModal.updateSuccess': 'Segment has been successfully updated',

  'pages.contact.segment.export.title': 'Export Segment',

  // Segment Details
  'pages.contact.segmentContacts.deleteContactButton': 'Remove Contact',
  'pages.contact.segmentContacts.total': 'Total contact: {number}',
  'pages.contact.segmentContacts.emptyTable': 'No contact to display',
  'pages.contact.segmentContacts.searchPlaceholder': 'Search contacts',
  'pages.contact.segmentContacts.deleteSegmentButton': 'Delete Segment',
  'pages.contact.segmentContacts.deleteTitle': 'Remove these contacts?',
  'pages.contact.segmentContacts.deleteDescription':
    'The selected contacts will be removed from this segment',
  'pages.contact.segmentContacts.deleteYes': 'Remove',
  'pages.contact.segmentContacts.deleteNo': 'Cancel',
  'pages.contact.segmentContacts.deleteSuccess': 'Contacts have been successfully removed',
};
