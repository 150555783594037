// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/opt/atlassian/pipelines/agent/build/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'/opt/atlassian/pipelines/agent/build/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__LandingPage' */'/opt/atlassian/pipelines/agent/build/src/pages/LandingPage'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/help",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__HelpGuest' */'/opt/atlassian/pipelines/agent/build/src/pages/HelpGuest'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/dashboard",
        "routes": [
          {
            "name": "login",
            "path": "/dashboard/login",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'/opt/atlassian/pipelines/agent/build/src/pages/Login'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "path": "/dashboard",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'/opt/atlassian/pipelines/agent/build/src/layouts/SecurityLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/dashboard",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'/opt/atlassian/pipelines/agent/build/src/layouts/BasicLayout'), loading: LoadingComponent}),
                "authority": [
                  "viewer"
                ],
                "routes": [
                  {
                    "path": "/dashboard",
                    "name": "home",
                    "icon": "home",
                    "redirect": "/dashboard/home",
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/dashboard/home",
                    "name": "home",
                    "icon": "home",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Home' */'/opt/atlassian/pipelines/agent/build/src/pages/Home'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/dashboard/message/create",
                    "name": "createMessage",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__CreateMessage' */'/opt/atlassian/pipelines/agent/build/src/pages/CreateMessage'), loading: LoadingComponent}),
                    "authority": [
                      "viewer"
                    ],
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/dashboard/message/:campaignId",
                    "name": "messageDetails",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Message__MessageDetails' */'/opt/atlassian/pipelines/agent/build/src/pages/Message/MessageDetails'), loading: LoadingComponent}),
                    "authority": [
                      "viewer"
                    ],
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/dashboard/message",
                    "name": "message",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Message' */'/opt/atlassian/pipelines/agent/build/src/pages/Message'), loading: LoadingComponent}),
                    "icon": "message",
                    "authority": [
                      "viewer"
                    ],
                    "exact": true
                  },
                  {
                    "path": "/dashboard/contacts/segment/:segmentId",
                    "name": "segmentDetails",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contacts__SegmentContacts' */'/opt/atlassian/pipelines/agent/build/src/pages/Contacts/SegmentContacts'), loading: LoadingComponent}),
                    "authority": [
                      "viewer"
                    ],
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "path": "/dashboard/contacts",
                    "name": "contact",
                    "icon": "contacts",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Contacts' */'/opt/atlassian/pipelines/agent/build/src/pages/Contacts'), loading: LoadingComponent}),
                    "authority": [
                      "viewer"
                    ],
                    "exact": true
                  },
                  {
                    "path": "/dashboard/templates",
                    "name": "template",
                    "icon": "appstoreAdd",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Template' */'/opt/atlassian/pipelines/agent/build/src/pages/Template'), loading: LoadingComponent}),
                    "authority": [
                      "viewer"
                    ],
                    "exact": true
                  },
                  {
                    "path": "/dashboard/help",
                    "name": "help",
                    "icon": "questionCircle",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Help' */'/opt/atlassian/pipelines/agent/build/src/pages/Help'), loading: LoadingComponent}),
                    "authority": [
                      "viewer"
                    ],
                    "exact": true
                  },
                  {
                    "path": "/dashboard/admin",
                    "name": "admin",
                    "icon": "crown",
                    "authority": [
                      "superadmin"
                    ],
                    "routes": [
                      {
                        "path": "/dashboard/admin/users",
                        "name": "users",
                        "icon": "contacts",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Users__List' */'/opt/atlassian/pipelines/agent/build/src/pages/Admin/Users/List'), loading: LoadingComponent}),
                        "authority": [
                          "superadmin"
                        ],
                        "exact": true
                      },
                      {
                        "path": "/dashboard/admin/organizations",
                        "name": "organizations",
                        "icon": "contacts",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Organizations__List' */'/opt/atlassian/pipelines/agent/build/src/pages/Admin/Organizations/List'), loading: LoadingComponent}),
                        "authority": [
                          "superadmin"
                        ],
                        "exact": true
                      },
                      {
                        "path": "/dashboard/admin/performance",
                        "name": "performance",
                        "icon": "contacts",
                        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Admin__Performance' */'/opt/atlassian/pipelines/agent/build/src/pages/Admin/Performance'), loading: LoadingComponent}),
                        "authority": [
                          "superadmin"
                        ],
                        "exact": true
                      }
                    ]
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/atlassian/pipelines/agent/build/src/pages/404'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/atlassian/pipelines/agent/build/src/pages/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/opt/atlassian/pipelines/agent/build/src/pages/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
