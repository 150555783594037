export default {
  'pages.admin.user.title': 'Kelola Pengguna',
  'pages.admin.user.addNewUser': 'Tambah Pengguna Baru',

  'pages.admin.user.searchUser': 'Cari Pengguna',
  'pages.admin.user.columnId': 'ID',
  'pages.admin.user.columnUsername': 'Username',
  'pages.admin.user.columnOrganization': 'Organisasi',
  'pages.admin.user.columnRole': 'Peran',
  'pages.admin.user.columnActive': 'Aktif',
  'pages.admin.user.columnLocked': 'Terkunci',
  'pages.admin.user.columnLastLogin': 'Terakhir masuk',
  'pages.admin.user.columnAction': 'Aksi',
  'pages.admin.user.action.resetPassword': 'Reset Password',

  'pages.admin.user.actionMessage': 'Apakah kamu yakin ingin {action} pengguna ini?',
  'pages.admin.user.actionMessage.activate': 'aktivasi',
  'pages.admin.user.actionMessage.deactivate': 'deaktivasi',
  'pages.admin.user.actionMessage.lock': 'mengunci',
  'pages.admin.user.actionMessage.unlock': 'melepas kunci',

  'pages.admin.user.createModal.title': 'Tambah Pengguna Baru',
  'pages.admin.user.createModal.username': 'Username',
  'pages.admin.user.createModal.usernamePlaceholder': 'Masukkan username',
  'pages.admin.user.createModal.usernameRequired': 'Username wajib diisi',
  'pages.admin.user.createModal.password': 'Password',
  'pages.admin.user.createModal.passwordPlaceholder': 'Masukkan password',
  'pages.admin.user.createModal.passwordRequired': 'Password wajib diisi',
  'pages.admin.user.createModal.email': 'Email',
  'pages.admin.user.createModal.emailPlaceholder': 'Masukkan email',
  'pages.admin.user.createModal.emailRequired': 'Email wajib diisi',
  'pages.admin.user.createModal.emailInvalid': 'Format email salah',
  'pages.admin.user.createModal.organization': 'Organisasi',
  'pages.admin.user.createModal.organizationPlaceholder': 'Pilih Organisasi',
  'pages.admin.user.createModal.organizationRequired': 'Organisasi wajib dipilih',
  'pages.admin.user.createModal.role': 'Peran',
  'pages.admin.user.createModal.rolePlaceholder': 'Pilih Peran',
  'pages.admin.user.createModal.roleRequired': 'Peran wajib dipilih',
  'pages.admin.user.createModal.cancel': 'Batal',
  'pages.admin.user.createModal.submit': 'Buat Pengguna Baru',

  'pages.admin.user.resetPasswordModal.title': 'Ganti Password Pengguna',
  'pages.admin.user.resetPasswordModal.newPassword': 'Password Baru',
  'pages.admin.user.resetPasswordModal.newPasswordPlaceholder': 'Masukkan password baru',
  'pages.admin.user.resetPasswordModal.newPasswordRequired': 'Password baru wajib diisi',
  'pages.admin.user.resetPasswordModal.cancel': 'Batal',
  'pages.admin.user.resetPasswordModal.submit': 'Ganti Password',

  'pages.admin.user.createUserSuccess': 'Pengguna baru berhasil dibuat',
  'pages.admin.user.updateSuccess': 'Data pengguna berhasil diperbarui',
  'pages.admin.user.updateFailed': 'Data pengguna gagal diperbarui',
  'pages.admin.user.usernameTaken': 'Username sudah digunakan',

  'pages.admin.organization.title': 'Kelola Organisasi',
  'pages.admin.organization.addNewOrganization': 'Tambah Organisasi Baru',

  'pages.admin.organization.actionMessage': 'Apakah kamu yakin ingin {action} pengguna ini?',
  'pages.admin.organization.actionMessage.activate': 'aktivasi',
  'pages.admin.organization.actionMessage.deactivate': 'deaktivasi',

  'pages.admin.organization.searchOrganization': 'Cari Organisasi',
  'pages.admin.organization.columnId': 'ID',
  'pages.admin.organization.columnName': 'Nama',
  'pages.admin.organization.columnActive': 'Aktif',
  'pages.admin.organization.columnAction': 'Aksi',

  'pages.admin.organization.createModal.title': 'Tambah Organisasi Baru',
  'pages.admin.organization.createModal.name': 'Nama',
  'pages.admin.organization.createModal.namePlaceholder': 'Masukkan nama',
  'pages.admin.organization.createModal.nameRequired': 'Nama wajib diisi',
  'pages.admin.organization.createModal.cancel': 'Batal',
  'pages.admin.organization.createModal.submit': 'Buat Organisasi Baru',

  'pages.admin.organization.createOrganizationSuccess': 'Organisasi baru berhasil dibuat',
  'pages.admin.organization.updateSuccess': 'Data organisasi berhasil diperbarui',
  'pages.admin.organization.updateFailed': 'Data organisasi batal diperbarui',
  'pages.admin.organization.nameTaken': 'Nama organisasi sudah digunakan',

  'pages.admin.performance.title': 'Performa',
  'pages.admin.performance.stats':
    'Statistik Pengguna (Kontak: {contact}, Pesan: {message}, Template: {template})',

  'pages.admin.performance.columnUsername': 'Username',
  'pages.admin.performance.columnUsernameSearch': 'Cari username',
  'pages.admin.performance.columnOrganization': 'Organisasi',
  'pages.admin.performance.columnOrganizationSearch': 'Cari Organisasi',
  'pages.admin.performance.columnLastLogin': 'Terakhir masuk',
  'pages.admin.performance.columnTemplate': 'Template',
  'pages.admin.performance.columnMessageSent': 'Pesan Terkirim',
  'pages.admin.performance.columnContactsSaved': 'Kontak Tersimpan',

  'pages.error.notHandled': 'Gagal meluncur, silakan hubungi admin',
};
