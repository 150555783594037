import landingPage from './landingPage';
import login from './login';
import home from './home';
import template from './template';
import createMessage from './createMessage';
import contact from './contact';
import message from './message';
import help from './help';
import admin from './admin';

export default {
  ...landingPage,
  ...login,
  ...home,
  ...template,
  ...createMessage,
  ...contact,
  ...message,
  ...help,
  ...admin,
};
