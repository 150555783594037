export default {
  'pages.template.title': 'Template',
  'pages.template.description': 'Buat dan kelola template kamu',
  'pages.template.searchPlaceholder': 'Cari template',
  'pages.template.emptyTable': 'Tidak ada template untuk ditampilkan',
  'pages.template.createTemplate': 'Buat Template',
  'pages.template.columnName': 'Judul',
  'pages.template.columnMessage': 'Pesan',
  'pages.template.columnAttachment': 'Lampiran',
  'pages.template.columnAction': 'Aksi',
  'pages.template.action.edit': 'Ubah',
  'pages.template.action.delete': 'Hapus',

  'pages.template.createModal.title': 'Buat Template',
  'pages.template.createModal.editTitle': 'Ubah Template',
  'pages.template.createModal.nameTitle': 'Nama Template',
  'pages.template.createModal.namePlaceholder': 'Masukkan nama template',
  'pages.template.createModal.nameRequired': 'Nama template wajib diisi',
  'pages.template.createModal.messageTitle': 'Pesan kamu',
  'pages.template.createModal.messagePlaceholder': 'Masukkan pesan kamu',
  'pages.template.createModal.messageRequired': 'Pesan wajib diisi',
  'pages.template.createModal.messageDescription':
    "Gunakan Custom Field dengan mengetik '{{customFieldmu}}'",
  'pages.template.createModal.cancel': 'Batal',
  'pages.template.createModal.submit': 'Buat',
  'pages.template.createModal.editSubmit': 'Simpan',
  'pages.template.createModal.createSuccess': 'Template telah berhasil dibuat',
  'pages.template.createModal.updateSuccess': 'Templat telah berhasil diperbarui',

  'pages.template.deleteTitle': 'Hapus template ini?',
  'pages.template.deleteDescription': 'Template ini akan dihapus secara permanen',
  'pages.template.deleteYes': 'Hapus',
  'pages.template.deleteNo': 'Batal',
  'pages.template.deleteSuccess': 'Template telah berhasil dihapus',
};
