import request from '@/utils/request';

export interface GetUserParams {
  limit: number;
  page: number;
  order: string;
  orderBy: string;
}

export interface CreateUserPayload {
  username: string;
  password: string;
  email: string;
  role: string;
  isActive: true | false;
  isLocked: true | false;
}

export type UpdateUserPayload = Partial<CreateUserPayload> & {
  id: string;
  newPassword?: string;
  delay?: Record<string, number>;
};

export async function query() {
  return request('/api/users');
}

export async function queryCurrent() {
  return request('/api/currentUser');
}

export async function queryNotices() {
  return request('/api/notices');
}

export async function getUsers(params: GetUserParams) {
  return request('/users', {
    params,
  });
}

export async function getUserData() {
  return request.get(`/user/me`);
}

export async function createUser(payload: CreateUserPayload) {
  return request.post('/user', {
    data: payload,
  });
}

export async function updateUser(variables: UpdateUserPayload) {
  const { id, ...updates } = variables;
  return request.put(`/user/${id}`, {
    data: updates,
  });
}

export async function updateUserAdmin(variables: UpdateUserPayload) {
  const { id, ...updates } = variables;
  return request.put(`/admin/user/${id}`, {
    data: updates,
  });
}

export async function getWAInfo() {
  return request.get(`/WA/info`);
}
