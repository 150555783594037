export default {
  'component.avatarDropdown.menu.logout': 'Keluar',

  'component.mediaUpload.addMedia': 'Tambahkan File / Gambar',
  'component.mediaUpload.sizeLimitMB': 'Ukuran file melebihi batas ukuran maksimal ({mb} MB)',
  'component.mediaUpload.mediaConfig.position': 'Posisi lampiran:',
  'component.mediaUpload.mediaConfig.before': 'Sebelum Pesan',
  'component.mediaUpload.mediaConfig.after': 'Setelah Pesan',
  'component.mediaUpload.mediaConfig.caption': 'Dalam Teks',

  'component.modal.export.name': 'Beri nama file',
  'component.modal.export.namePrefix': 'Nama file',
  'component.modal.export.cancel': 'Batal',
  'component.modal.export.submit': 'Ekspor',

  'component.pagination.totalString': '{range1}-{range2} dari {total} item',

  'component.modal.editAntiBlock.title': 'Pengaturan Pesan',
  'component.modal.editAntiBlock.alert':
    'Kamu dapat menyesuaikan pengaturan anti blok tetapi kami menyarankan untuk mempertahankan pengaturan saat ini',
  'component.modal.editAntiBlock.delayDuration.title': 'Penundaan per Pesan (detik)',
  'component.modal.editAntiBlock.delayDuration.description':
    'Waktu tunda antara setiap pesan yang dikirim',
  'component.modal.editAntiBlock.sleepCount.title': 'Jumlah Pesan yang Dikirim Sebelum Tidur',
  'component.modal.editAntiBlock.sleepCount.description':
    'Total pesan diperlukan untuk memicu tidur',
  'component.modal.editAntiBlock.sleepDuration.title': 'Durasi Tidur (secs)',
  'component.modal.editAntiBlock.sleepDuration.description': 'Durasi lamanya tidur',

  'component.modal.editAntiBlock.cancel': 'Batal',
  'component.modal.editAntiBlock.reset': 'Kembalikan Semula',
  'component.modal.editAntiBlock.submit': 'Simpan Perubahan',

  'component.modal.editAntiBlock.popupReset.normal':
    'Ini akan menghapus semua pengaturan kamu saat ini. Apakah kamu yakin ingin melanjutkan?',
  'component.modal.editAntiBlock.popupReset.cancel': 'Batal',
  'component.modal.editAntiBlock.popupReset.submit': 'Kembalikan',

  'component.modal.editAntiBlock.popupConfirm.normal': 'Apakah kamu yakin ingin melanjutkan?',
  'component.modal.editAntiBlock.popupConfirm.below':
    'Beberapa pengaturan kamu lebih rendah dari saran kami. Apakah kamu yakin ingin melanjutkan?',
  'component.modal.editAntiBlock.popupConfirm.cancel': 'Batal',
  'component.modal.editAntiBlock.popupConfirm.submit': 'Lanjutkan Simpan',
};
