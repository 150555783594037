/** Request 网络请求工具 更详细的 api 文档: https://github.com/umijs/umi-request */
import Cookies from 'js-cookie';
import { history } from 'umi';
import { extend } from 'umi-request';
import { notification } from 'antd';

import crashLogger from '@/utils/logger';
import { getIntl } from '@/utils/utils';

// const filterList = [`${SERVER_BASE_URL}/auth/me`];

/** 异常处理程序 */
const errorHandler = (error: any): any => {
  const { formatMessage } = getIntl();
  const { request, response, data } = error;

  if (response.status === 401) {
    notification.error({
      message: formatMessage({ id: 'error.unauthorized.title' }),
      description: formatMessage({ id: 'error.unauthorized.description' }),
    });
    history.push('/dashboard/login');
    Cookies.remove('sendall-token');
  } else {
    error.customContext = `${request?.url} | statusCode: ${data?.statusCode} | ${data?.message}`;
    crashLogger.error(error, 'error');
    if (response) {
      // if ((response && filterList.includes(response.url)) || error.data.errorCode) throw error;

      const errorText = formatMessage({ id: `error.status.${response.status}` });
      // let errorText = formatMessage({id: `error.status.${response.status}`});
      // if (data && data.message) {
      //   errorText = ` ${data.message}`;
      // }

      notification.error({
        message: formatMessage({ id: 'error.general.title' }) + ' - ' + response.status,
        description: errorText,
      });
    } else {
      notification.error({
        description: formatMessage({ id: 'error.unknown.title' }),
        message: formatMessage({ id: 'error.unknown.description' }),
      });
    }

    throw error;
  }
};

const request = extend({
  prefix: SERVER_BASE_URL,
  errorHandler,
});

request.interceptors.request.use((url, options) => {
  const token = Cookies.get('sendall-token');
  options.headers = {
    ...options.headers,
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
  return {
    url,
    options,
  };
});

export default request;
